import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import "../../utils/i18n";
import { useTranslation } from "react-i18next";
import DrawerFilterSatisfaction from "../../components/DrawerFilterSatisfaction";
import AnchorTemporaryDrawer from "../../components/AnchorTemporaryDrawer";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import LoginProfile from "../../hooks/useLoginUser";

const mocklineChart = [
  {
    "id": 0,
    "data": [
        {
            "x": "",
            "y": 0
        },
    ]
  }
];

const mockKeyLineChart = ['People'];


const EnergyMotivationDashboard = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [energiaMotivacao, setEnergiaMotivacao] = useState([]);
  const [lineChart, setLineChart] = useState([]);
  const [keysLineChart, setKeysLineChart] = useState([]);
  const [energyLevel, setEnergyLevel] = useState([]);
  const [motivationLevel, setMotivationLevel] = useState([]);
  const [keysEnergyLevel, setKeysEnergyLevel] = useState([]);
  const [keysMotivationLevel, setKeysMotivationLevel] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filter, setFilter] = useState({
    selectTeam: 0,
    people_target: 0,
    isFilter: false,
  });


  const location = useLocation();

  const api = process.env.REACT_APP_API_URL;

  const motivationColor = ["hsl(9, 87%, 67%)"];

  const childToParent = (data) =>{
    switch(data.id){
      case(t("Development Enviroment")):
        //setClassification(data.id);
        break;
      case(t("Quality of Life")):
        //setClassification(data.id);
        break;
      case(t("Work Enviroment")):
        //setClassification(data.id);
        break;
      case(t("Grooming")):
        //setClassification(data.id);
        break;
      case(t("Operational")):
        //setClassification(data.id);
        break;
      case(t("Tactical")):
        //setClassification(data.id);
        break;
      case(t("Strategic")):
        //setClassification(data.id);
        break;
      default:
        console.log('caiu no default (childParent)');
    };
  };

  //const {TeamProfile.getAccountID(),campaign_id} = useParams();

  const fetchHappinessEnergyMotivation = async () => {
    const urlTimeLine = `${api}/motivacaoenergia/avgLineChart/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
      }
    };
    const respTimeLine = await fetch(urlTimeLine, options);
    const jsonTimeLine = await respTimeLine.json();
    setLineChart(jsonTimeLine);
    setKeysLineChart(handleKeys(jsonTimeLine));
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": `${sessionStorage.getItem('token')}`
          }
        };

        const resp = await fetch(url, options);
        return resp.json();
      }

      try {
        const [energyMotivationResponse,energyLevelResponse,motivationLevelResponse,lineChartResponse] = await Promise.all([
          fetchWithHeaders(`${api}/motivacaoenergia/avgbignumbersmotivacaoenergia/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}`),
          fetchWithHeaders(`${api}/motivacaoenergia/avgTimeLineMotivacaoEnergia/${LoginProfile.getUserId()}/team/${filter.selectTeam}/item/${t("Energy")}/people_target/${filter.people_target}`),
          fetchWithHeaders(`${api}/motivacaoenergia/avgTimeLineMotivacaoEnergia/${LoginProfile.getUserId()}/team/${filter.selectTeam}/item/${t("Motivation")}/people_target/${filter.people_target}`)
        ]);
    
        setEnergiaMotivacao(energyMotivationResponse);
        setEnergyLevel(energyLevelResponse);
        setKeysEnergyLevel(handleKeys(energyLevelResponse));
        setMotivationLevel(motivationLevelResponse);
        setKeysMotivationLevel(handleKeys(motivationLevelResponse));
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
      
    };
    
    fetchHappinessEnergyMotivation();
    fetchData();

  }, [filter, location.pathname]);

  const handleKeys = (peopleTarget) => {
    const key = new Array();
    if(peopleTarget){
      peopleTarget.map((itens) =>{
        if(!key.includes(t(itens.item))){
          key.push(t(itens.item));
        }
        return t(itens.item);
      });
    }
    return key;
  };

  let energy_score;
  let motivation_score;

  if(energiaMotivacao){
    energiaMotivacao.map((itens) => {
      switch(itens.item){
        case (t("Energy")):
          energy_score = itens.score;
          break;
        case (t("Motivation")):
          motivation_score = itens.score;
          break;
        default:
          return "Default";
      }
      return itens.score;
    });
  }

  const handleIncrease = (score) => {
    let result = (parseFloat(score) / 5) * 100 ;
    return result
  };

  const handleToFixedIncrease = (score) => {
    return score.toFixed(2);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleFiltersChange = (selectFilters) => {
    setFilter(selectFilters);
    console.log("filtro aplicado", selectFilters);
  }


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Energy & Motivation Dashboard")} subtitle={t("Welcome to energy and motivation level dashboard")} />
        <Box>
        <Button
              onClick={handleDrawerToggle}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: filter.isFilter === true ? colors.greenAccent[400] : colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              {filter.isFilter === true ? <FilterAltOutlinedIcon sx={{ mr: "10px" }} /> : <TuneOutlinedIcon sx={{ mr: "10px" }} />}
              {t("Filter")}
            </Button>
        </Box>
      </Box>
      <DrawerFilterSatisfaction 
        open={openDrawer}
        onClose={handleDrawerToggle}
        onApplyFilters={handleFiltersChange}
      />
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={energy_score ? handleToFixedIncrease(energy_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Energy")}
            progress={handleIncrease(energy_score) / 100}
            increase={handleIncrease(energy_score).toFixed(0) + "%"}
            icon={
              <ElectricBoltOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={motivation_score ? handleToFixedIncrease(motivation_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Motivation")}
            progress={handleIncrease(motivation_score) / 100}
            increase={handleIncrease(motivation_score).toFixed(0) + "%"}
            icon={
              <AutoAwesomeOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Energy level")}
          </Typography>
          <Box height="85%" mt="-20px">
            <BarChart isDashboard={true}
                      data={energyLevel ? energyLevel : null}
                      keys={keysEnergyLevel ? keysEnergyLevel : null}
                      indexBy="initial_date"
                      groupMode="stack"
                      childToParent={childToParent}
                      interactive={false}
                      maxValue={5.0}
                      tickRotation={energyLevel && energyLevel.length > 9 ? -15 : 0}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Motivation level")}
          </Typography>
          <Box height="85%" mt="-20px">
            <BarChart isDashboard={true}
                      data={motivationLevel ? motivationLevel : null}
                      keys={keysMotivationLevel ? keysMotivationLevel: null}
                      indexBy="initial_date"
                      groupMode="stack"
                      childToParent={childToParent}
                      interactive={false}
                      color={motivationColor}
                      maxValue={5.0}
                      tickRotation={motivationLevel && motivationLevel.length > 9 ? -15 : 0}
                      />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Energy and Motivation timeline")}
          </Typography>
          <Box height="85%" mt="-20px">
            <LineChart isDashboard={false} data={lineChart ? lineChart : mocklineChart} keys={keysLineChart ? keysLineChart : mockKeyLineChart} maxValue={5.0}  />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EnergyMotivationDashboard;
