import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
import '../utils/i18n';
import { useTranslation } from "react-i18next";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';

const ChallengeBox = ({id, title, subtitle, icon, selected, onSelected, clickStatus, action, initial_date, end_date }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  return (
    <Box
      backgroundColor={colors.primary[400]}
      display="flex"
      borderRadius="10px"
      padding="20px"
      onClick={() => onSelected(id)}
      sx={{
        border: selected ? '2px solid #016787' : 'none',
        margin: '20px 20px',
        cursor: 'pointer'
      }}
      //width="95%"
    >
      <Box width="100%" m="0 30px">
        <Box display="flex" justifyContent="space-between">
          <Box width="35vh">
            {icon}
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.grey[100], padding: "22px 0", cursor: "pointer" }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Box
              minHeight="1vw"
            >
              <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: colors.grey[100], padding: "5px 0", cursor: "pointer" }}
            >
              {t("Action")}:
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-start" >
            <Typography
                variant="h5"
                //fontWeight="bold"
                sx={{ color: colors.grey[100], padding: "22px 0", cursor: "pointer",width:"85vh" }}
              >
                {action}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box minHeight="2vw">
                {/** Espaço Vazio */}
              </Box>
              <Box display="flex" justifyContent="space-between" width="18vh" sx={{ padding: "10px 0"}}>
                <Button
                  id={id}
                  onClick={() => clickStatus(id)}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                >
                      <DoneAllOutlinedIcon sx={{ mr: "10px" }} />
                      {t("Update Status")}
                    </Button>
              </Box>
          </Box>
          
        </Box>
        <Box display="flex" justifyContent="space-between" mt="1px">
        <Box
            sx={{
              //border: '2px solid rgba(252, 249, 249, 0.1)',
              //padding: '8px',
              cursor: 'pointer',
              width:"35vh",
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: colors.greenAccent[500], padding: "8px 0", cursor: "pointer" }}>
            {subtitle}
          </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="85vh"
            gap={2}
          >
          <Typography variant="h6" sx={{ color: colors.greenAccent[500] }}>
            {t("Start Date")}: {initial_date}
          </Typography>
          <Typography variant="h6" sx={{ color: colors.greenAccent[500] }}>
            {t("End Date")}: {end_date}
          </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="18vh"
          >
            <Typography
            variant="h6"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600]}}
          >
            {/* Espaço Vazio */}
          </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChallengeBox;
