import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { mockPaises } from '../../../data/mockPaises copy'
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import {useFetch} from '../../../hooks/useFetch';
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";


const FormAccountData = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [method,setMethod] = useState(null);
  const [data, setData] = useState([]);
  const [contact, setContact] = useState([]);
  const [login, setLogin] = useState([]);
  const [phoneError, setPhoneError] = useState(null);
  const [phoneCellError, setPhoneCellError] = useState(null);

  //recebe id do account para carregar o form para edição
  
  const { id } = useParams();
  const api = process.env.REACT_APP_API_URL;
  //----------------------------------------------------

  useEffect(()=> {
    const fetchAccountAddress = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": `${sessionStorage.getItem('token')}`
          }
        };

        const resp = await fetch(url, options);
        return resp.json();
      }
      try{
        const [accountResponse, contactResponse, loginResponse] = await Promise.all([
          fetchWithHeaders(`${api}/account/${id}`),
          fetchWithHeaders(`${api}/contact/${id}`),
        ]);
        setData(accountResponse);
        setContact(contactResponse);
        setLogin(loginResponse);
      }
      catch (error){
        console.error('Error fetching data:', error);
      } 
    };

    fetchAccountAddress();
  },[id]);
  

  initialValues.email = `${data.email ? data.email : ""}`;
  initialValues.type = `${data.type ? data.type : ""}`;
  initialValues.identifier = `${data.identifier ? data.identifier : ""}`;
  initialValues.company = `${data.company ? data.company : ""}`;
  initialValues.phone = `${data.phone ? data.phone : ""}`;
  initialValues.phoneCell = `${contact.phone2 ? contact.phone2 : ""}`;
  initialValues.address = `${contact.address ? contact.address : ""}`;
  initialValues.city = `${contact.city ? contact.city : ""}`;
  initialValues.country = `${contact.country ? contact.country : ""}`;

  //seta url para cadastro (POST) e grava o retorno da API no Resp
  const [resp, setResp] = useState(null);
  //-------------------------------------------------------------

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const accountUrl = `${api}/account/cadastro`;
    const contactUrl = `${api}/contact/cadastro`;
    
    const account = {
      account_id : id,
      email : values.email,
      phone : values.phone,
      type : values.type,
      identifier : values.identifier,
      company : values.company
    };
   
    const reqAccount = await fetch(accountUrl,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization" : `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(account),
    });
    setResp(reqAccount.status);

    const contact = {
      phone2 : values.phoneCell,
      address : values.address,
      city : values.city,
      country : values.country,
      account_id : id
    };


    const reqContact = await fetch(contactUrl,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization" : `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(contact),
    });
    
  };
  //------------------------------------------------

  const handleValidaTelefone = (phone) => {
    const telefoneRegEx = /^\([1-9]{2}\)9[0-9]{4}\-[0-9]{4}$/;
    if (telefoneRegEx.test(phone)){
      return true;
    }
    else{
      return false;
    }
  };

  const handleLabel = () => {
    if(id){
      return "Update Account";
    }
      return "Create Account";
  };

  return (
    <Box m="0px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Company")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company}
                name="company"
                error={!!touched.company && !!errors.company}
                helperText={touched.company && errors.company}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Type")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Identifier")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.identifier}
                name="identifier"
                error={!!touched.identifier && !!errors.identifier}
                helperText={touched.identifier && errors.identifier}
                sx={{ gridColumn: "span 3" }}
              />
              <InputMask
                mask={'(99)99999-9999'}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                onBlur={(e) => {
                  if(!handleValidaTelefone(e.target.value)){
                    setPhoneError("Telefone inválido");
                  }
                  else{
                    setPhoneError(null);
                  }
                }}
                value={values.phone}
                name="phone"
                error={!!(touched.phone && errors.phone) || !!phoneError} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.phone && errors.phone ? errors.phone : phoneError} 
                sx={{ gridColumn: "span 2" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label="Telefone" />}
              </InputMask>
              <InputMask
                mask={'(99)9999-9999'}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                onBlur={(e) => {
                  if(!handleValidaTelefone(e.target.value)){
                    setPhoneCellError("Telefone inválido");
                  }
                  else{
                    setPhoneCellError(null);
                  }
                }}
                value={values.phoneCell}
                name="phoneCell"
                error={!!(touched.phoneCell && errors.phoneCell) || !!phoneError} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.phoneCell && errors.phoneCell ? errors.phoneCell : phoneError} 
                sx={{ gridColumn: "span 2" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label="Telefone" />}
              </InputMask>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Address")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("City")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                <InputLabel>{t("Country")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Country")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.country}
                  name="country"
                  error={!!touched.country && !!errors.country}
                  helperText={touched.country && errors.country}
                  sx={{ gridColumn: "span 2" }}
                  >
                    {mockPaises.map((pais)=>(
                      <MenuItem value={pais.sigla}>{t(pais.nome_pais)}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <SnackBars buttonName={t(handleLabel().toUpperCase())} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address: yup.string().required("required"),
  city: yup.string().required("required"),
  country: yup.string().required("required"),
});
const initialValues = {
  email: "",
  type : "",
  identifier : "",
  phone: "",
  phoneCell: "",
  address: "",
  city: "",
  country: "",
};

export default FormAccountData;