import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import LoginProfile from "../../hooks/useLoginUser";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import DrawerFilterSatisfaction from "../../components/DrawerFilterSatisfaction";
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import StatAction from "../../components/StatAction";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

const mockKeyLineChart = ['People'];

const mocklineChart = [
  {
    "id": "step_sent",
    "value": 69172,
    "label": "Sent"
  },
  {
    "id": "step_viewed",
    "value": 44992,
    "label": "Viewed"
  },
  {
    "id": "step_clicked",
    "value": 37718,
    "label": "Clicked"
  },
  {
    "id": "step_add_to_card",
    "value": 33905,
    "label": "Add To Card"
  },
  {
    "id": "step_purchased",
    "value": 32093,
    "label": "Purchased"
  }
];

const PdiDashboard = () => {
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bigNumberScore, setBigNumberScore] = useState([]);
  const [bigNumberFunnel, setBigNumberFunnel] = useState([0]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bignumber, setBignumber] = useState([]);
  const [planned, setPlanned] = useState(0);
  const [to_do, setToDo] = useState(0);
  const [doing, setDoing] = useState(0);
  const [delayed, setDelayed] = useState(0);
  const [done, setDone] = useState(0);
  const [list, setList] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [filter, setFilter] = useState({
    selectTeam: 0,
    people_target: 0,
    isFilter: false,
  });

  const location = useLocation();

  const api = process.env.REACT_APP_API_URL;
  
  //const {campaign_id} = useParams();

  const fetchCampaing = async () => {
    const url = `${api}/actionplan/listpdiperformance/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}/language/${localStorage.getItem('language')}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
  
    setCampaign(json);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": sessionStorage.getItem('token')
          }
        };
        const resp = await fetch(url, options);
        return resp.json();

      };
      try{
        const [bigNumberScoreResponse, bigNumberFunnelResponse] = await Promise.all([
          fetchWithHeaders(`${api}/actionplan/bignumberpdidashboard/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}`),
          fetchWithHeaders(`${api}/actionplan/bignumberfunnel/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}`)
        ]);
        const { planned, to_do, doing, delayed, done } = bigNumberScoreResponse;
        setPlanned(planned);
        setToDo(to_do);
        setDoing(doing);
        setDelayed(delayed);
        setDone(done);
        setBignumber(bigNumberScoreResponse);
        setBigNumberFunnel(bigNumberFunnelResponse);
      }
      catch (error) {
        console.error("Error fetching data", error);
      }

    };
    fetchData();
    fetchCampaing();
  }, [filter]);  


  const handleIncrease = (score) => {
    let result = (parseFloat(score) / 5) * 100 ;
    return result
  };

  const handleToFixedIncrease = (score) => {
    return score.toFixed(2);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleFiltersChange = (selectFilters) => {
    setFilter(selectFilters);
  }

  const handlePercent = (planejado, realizado) => {
    if(realizado === 0){
      return 0;
    }
    else{
      try{
        let result = ((realizado / planejado) * 100);
        return result.toFixed(0);
      }
      catch(error){
        console.log(error);
        return error;
      }
    }
  }

  const columns = [
    {field: "id", headerName: "ID",flex:0.2},
    {
      field: "user",
      headerName: t("Employee"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "manager",
      headerName: t("Manager"),
      flex: 1,
    },
    {
      field: "end_date",
      headerName: t("End Date"),
      flex: 1,
    },
    {
      field: "pdi_status",
      headerName: t("Status"),
      flex: 1,
    },
    {
      field: "percent",
      headerName: t("Percent of Done"),
      flex: 1,
      renderCell: ({ row: { percent } }) => {
        return (
          <Box
            width="80%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              percent >= 70
                ? colors.greenAccent[700]
                : (percent >= 50) && (percent < 70)
                ? colors.blueAccent[500]
                : (percent >= 30) && (percent < 50)
                ? "#FF7c00"
                : "#E61919"
            }
            borderRadius="4px"
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {percent + "%"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "pdi_type",
      headerName: t("Type"),
      flex: 1,
    },
    {
      field: "objective",
      headerName: t("Objective"),
      flex: 2,
    },
  ];


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Personal Development Plan")} subtitle={t("Welcome to personal development plan dashboard")} />
        <Box>
          <Button
            onClick={handleDrawerToggle}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: filter.isFilter === true ? colors.greenAccent[400] : colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {filter.isFilter === true ? <FilterAltOutlinedIcon sx={{ mr: "10px" }} /> : <TuneOutlinedIcon sx={{ mr: "10px" }} />}
            {t("Filter")}
          </Button>
        </Box>
      </Box>
      <DrawerFilterSatisfaction 
        open={openDrawer}
        onClose={handleDrawerToggle}
        onApplyFilters={handleFiltersChange}
      />
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={planned}
            subtitle={t("Planned")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <DriveFileRenameOutlineOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={to_do}
            subtitle={t("To Do")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <StopCircleOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={doing}
            subtitle={t("Doing")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <PlayCircleOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={delayed}
            subtitle={t("Delayed")}
            progress={20 / 100}
            increase={20 + "%"}
            icon={
              <HistoryToggleOffOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={done}
            subtitle={t("Done")}
            progress={35 / 100}
            increase={35 + "%"}
            icon={
              <DoneAllOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={handlePercent(planned, done)}
            subtitle={t("Percent of Done")}
            progress={35 / 100}
            increase={35 + "%"}
            icon={
              <PercentOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/**Row 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          //backgroundColor={colors.primary[400]}
          p="5px"
          m="10px 0 0 0"
          borderRadius="10px"
        >
          <Typography variant="h4" fontWeight="600">
            {t("PDP List")}
          </Typography>
          {/** Vazio */}
          <Box
            m="5px 0 0 0"
            height="60vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-row": {
                borderBottom: "solid 1px",
                borderBottomColor: colors.grey[600],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid 
                checkboxSelection
                rows={campaign ? campaign : false }
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                onSelectionModelChange={setList}
              />
          </Box>
        </Box>
        {/** Row 3 */}
      </Box>
    </Box>
  );
};

export default PdiDashboard;
