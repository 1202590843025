import { Box, TextField,Select,FormControl,InputLabel,MenuItem } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import SnackBars from "../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {useFetch} from '../../hooks/useFetch';
import TeamProfile from "../../hooks/useTeam";
import LoginProfile from "../../hooks/useLoginUser";
import '../../utils/i18n';
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import TextEditor from "../../components/TextEditor";


const FormPDI = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);
  const [employer, setEmployer] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);

  const api = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------

  const fetchEmployer = async () => {
    const url = `${api}/user/team/${LoginProfile.getUserId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setEmployer(json);
  };

  const fechFeedback = async () => {
    const url = `${api}/pdi/${id}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);  
    setData(json);
    console.log("json",json);
  };

  useEffect(()=> {
    if(id){
      fechFeedback();
    }
    fetchEmployer();
      },[id]);


  initialValues.objective = `${data.objective ? data.objective : ""}`;
  initialValues.pdi_type = `${data.type ? data.type : ""}`;
  initialValues.realization_date = `${data.realization_date ? data.realization_date : ""}`;
  initialValues.objective = `${data.objective ? data.objective : ""}`;
  initialValues.start_date = `${data.start_date ? data.start_date : ""}`;
  initialValues.end_date = `${data.end_date ? data.end_date : ""}`;
  initialValues.employer = `${data.user_id ? data.user_id : ""}`;
  initialValues.anotation = `${data.anotation ? data.anotation : ""}`;

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  //--------------------------------------------------


  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/pdi/cadastro`;

    const feedback = {
      pdi_id : id,
      objective : values.objective,
      pdi_type : values.pdi_type,
      anotation: values.anotation,
      user_id : values.employer,
      start_date: handleDataHora(values.start_date),
      end_date: handleDataHora(values.end_date),
      manager_id: LoginProfile.getUserId(),
      anotation: values.anotation
    };

    console.log('feedback', feedback);

    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization": `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(feedback),
    });
    const { pdi_id } = await request.json();
    setResp(request.status);
    console.log("pdi retorno", request.status);
    if(request.ok){
      if(id){
        navigate(`/pdi`);
      }
      else{
        navigate(`/pdi`);
        //navigate(`/feedback/assessment/${feedback_id}`);
      }
    }
  };

  const maskDate = () => {
    switch(localStorage.getItem('language')){
      case "br":
        return '99/99/9999';
      case "es":
        return '99-99-9999';
      case "us":
        return '99-99-9999';
    }
    return 0;
  };
  
  const handleLabel = () => {
    if(id){
      return t("Update PDP");
    }
    return t("Create PDP");
  };

  return (
    <Box m="20px">
      <Header title={handleLabel()} subtitle={t("Create and manage your team’s PDP")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(12, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
              }}
            >
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Employee")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Employer")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.employer}
                  name="employer"
                  error={!!touched.employer && !!errors.employer}
                  helperText={touched.employer && errors.employer}
                  sx={{ gridColumn: "span 6" }}
                  >
                    {employer && employer.map((employ)=>(
                      <MenuItem value={employ.id}>{t(employ.name)}</MenuItem>  
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Type")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Type")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pdi_type}
                  name="pdi_type"
                  error={!!touched.pdi_type && !!errors.pdi_type}
                  helperText={touched.pdi_type && errors.pdi_type}
                  sx={{ gridColumn: "span 6" }}
                  >
                    <MenuItem value={t("Develop")}>{t("Develop")}</MenuItem>
                    <MenuItem value={t("Empower")}>{t("Empower")}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Objective")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.objective}
                name="objective"
                error={!!touched.objective && !!errors.objective}
                helperText={touched.objective && errors.objective}
                sx={{ gridColumn: "span 12" }}
              />
              <InputMask
                mask={maskDate()}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.start_date}
                name="start_date"
                error={!!(touched.start_date && errors.start_date)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.start_date && errors.start_date} 
                sx={{ gridColumn: "span 6" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Start Date")} />}
              </InputMask>
              <InputMask
                mask={maskDate()}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.end_date}
                name="end_date"
                error={!!(touched.end_date && errors.end_date)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.end_date && errors.end_date} 
                sx={{ gridColumn: "span 6" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("End Date")} />}
              </InputMask>
              <Field
                name="anotation"
                component={TextEditor}
                sx={{ gridColumn: "span 12" }}
                minLines={12}
                maxLines={18}
                disable
              />
            </Box>
            <SnackBars buttonName={t(handleLabel().toUpperCase())} buttonType="submit" resp={resp} disabled={LoginProfile.getProfileId() === 3 ? true : false} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  employer: yup.string().required("Required"),
  start_date: yup.string().required("Required"),
  end_date: yup.string().required("Required"),
});
const initialValues = {
  employer: "",
  objective: "",
  anotation: "",
  start_date: "",
  end_date: "",
  type: "",
};

export default FormPDI;