import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Header from "../../components/Header";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import RadarType from "../../components/RadarChart";
import TeamProfile from "../../hooks/useTeam";
import LoginProfile from "../../hooks/useLoginUser";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import DrawerFilterSatisfaction from "../../components/DrawerFilterSatisfaction";
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';


const mocklineChart = [
  {
    "id": 0,
    "data": [
        {
            "x": "",
            "y": 0
        },
    ]
  }
];

const mockKeyLineChart = ['People'];

const SkillsDashboard = () => {
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bigNumberScore, setBigNumberScore] = useState([]);
  const [avgSkills, setAvgSkills] = useState([]);
  const [hardSkill, setHardSkill] = useState([]);
  const [softSkill, setSoftkill] = useState([]);
  const [keysSoft, setKeysSoft] = useState([]);
  const [keysHard, setKeysHard] = useState([]);
  const [dataRadarChart, setDataRadarChart] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filter, setFilter] = useState({
    selectTeam: 0,
    people_target: 0,
    selectUser: 0,
    isFilter: false,
  });

  const location = useLocation();

  const api = process.env.REACT_APP_API_URL;
  
  //const {campaign_id} = useParams();

  let urlRadarChart = null;
  let urlBigNumbers = null;
  let urlAvgSkills = null;
  let urlAvgHardSkills = null;
  let urlAvgSoftSkills = null;

  const handleURL = (profile) => {
    if (profile === 3){
      urlRadarChart = `${api}/assessment/dataradarchart/0/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${LoginProfile.getUserId()}`;
      urlBigNumbers = `${api}/assessment/bignumbercoreskills/0/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${LoginProfile.getUserId()}`;
      urlAvgSkills = `${api}/assessment/avgskills/0/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${LoginProfile.getUserId()}`;
      urlAvgHardSkills = `${api}/assessment/avghardskill/0/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${LoginProfile.getUserId()}`;
      urlAvgSoftSkills = `${api}/assessment/avgsoftskill/0/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${LoginProfile.getUserId()}`;
    }
    else{
      urlRadarChart = `${api}/assessment/dataradarchart/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${filter.selectUser}`;
      urlBigNumbers = `${api}/assessment/bignumbercoreskills/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${filter.selectUser}`;
      urlAvgSkills = `${api}/assessment/avgskills/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${filter.selectUser}`;
      urlAvgHardSkills = `${api}/assessment/avghardskill/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${filter.selectUser}`;
      urlAvgSoftSkills = `${api}/assessment/avgsoftskill/${LoginProfile.getUserId()}/team/${filter.selectTeam}/people_target/${filter.people_target}/user/${filter.selectUser}`;
    }
  };

  handleURL(LoginProfile.getProfileId());

  const fetchRadarChart = async () => {
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": sessionStorage.getItem('token')
        }
    };
    const respTimeLine = await fetch(urlRadarChart, options);
    const jsonTimeLine = await respTimeLine.json(respTimeLine);
    setDataRadarChart(jsonTimeLine);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": sessionStorage.getItem('token')
          }
        };
        const resp = await fetch(url, options);
        return resp.json();

      };
      try{
        const [bigNumberScoreResponse, avgSkillsResponse, hardSkillResponse, softSkillResponse] = await Promise.all([
          fetchWithHeaders(urlBigNumbers),
          fetchWithHeaders(urlAvgSkills),
          fetchWithHeaders(urlAvgHardSkills),
          fetchWithHeaders(urlAvgSoftSkills),
        ]);
        setBigNumberScore(bigNumberScoreResponse); 
        setSoftkill(softSkillResponse);
        handleKeysSoftSkill(softSkillResponse);
        setHardSkill(hardSkillResponse);
        handleKeysHardSkill(hardSkillResponse);
        setAvgSkills(avgSkillsResponse);
      }
      catch (error) {
        console.error("Error fetching data", error);
      }

    };

    fetchRadarChart();
    fetchData();
  }, [TeamProfile.getTeamID(), filter]);

  const handleKeysHardSkill = (item) => {
    // eslint-disable-next-line no-array-constructor
    const key = new Array();
    if(item){
      item.map((itens) =>{
        key.push(itens.item);
        return key;
      });
    }
    setKeysHard(key);
  };

  const handleKeysSoftSkill = (item) => {
    const key = new Array();
    if(item){
      item.map((itens) =>{
        key.push(itens.item);
        return key;
      });
    }
    setKeysSoft(key);
  };

  let hardskill_score;
  let softskill_score;
  let total_score;
  

  if(bigNumberScore){
    bigNumberScore.map((itens) => {
      switch(t(itens.classification)){
        case t("Hardskill"):
          hardskill_score = itens.score;
          break;
        case t("Softskill"):
          softskill_score = itens.score;
          break;
        case t("Total"):
          total_score = itens.score;
        default:
      }
      return null;
    });
  }


  const handleIncrease = (score) => {
    let result = (parseFloat(score) / 5) * 100 ;
    return result
  };

  const handleToFixedIncrease = (score) => {
    return score.toFixed(2);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleFiltersChange = (selectFilters) => {
    setFilter(selectFilters);
    console.log("filtro aplicado", selectFilters);
  }

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Skill Levels")} subtitle={t("Welcome to skill levels dashboard")} />
        <Box>
          <Button
            onClick={handleDrawerToggle}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: filter.isFilter === true ? colors.greenAccent[400] : colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              display: LoginProfile.getProfileId() !== 3 ? "" : "none",
            }}
          >
            {filter.isFilter === true ? <FilterAltOutlinedIcon sx={{ mr: "10px" }} /> : <TuneOutlinedIcon sx={{ mr: "10px" }} />}
            {t("Filter")}
          </Button>
        </Box>
      </Box>
      <DrawerFilterSatisfaction 
        open={openDrawer}
        onClose={handleDrawerToggle}
        onApplyFilters={handleFiltersChange}
      />
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={total_score ? handleToFixedIncrease(total_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Average Score")}
            progress={handleIncrease(total_score) / 100}
            increase={handleIncrease(total_score).toFixed(0) + "%"}
            icon={
              <AssistantOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={softskill_score ? handleToFixedIncrease(softskill_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Soft Skills")}
            progress={handleIncrease(softskill_score) / 100}
            increase={handleIncrease(softskill_score).toFixed(0) + "%"}
            icon={
              <ManageAccountsOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={hardskill_score ? handleToFixedIncrease(hardskill_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Hard Skills")}
            progress={handleIncrease(hardskill_score) / 100}
            increase={handleIncrease(hardskill_score).toFixed(0) + "%"}
            icon={
              <LocalLibraryOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/**Row 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
          borderRadius="10px"
        >
          <Typography variant="h5" fontWeight="600">
            {t("Skills Radar")}
          </Typography>
          <RadarType data={dataRadarChart ? dataRadarChart : null} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
          </Box>
        </Box>
        {/* BAR CHART */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Skills Levels")}
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart
              isDashboard={true}
              data={avgSkills ? avgSkills : null}
              keys={['Hardskill','Softskill']}
              indexBy="classification"
              groupMode="stack"
              interactive={false}
              maxValue={5} 
              />
          </Box>
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Soft Skills")}
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart
              isDashboard={true}
              data={softSkill ? softSkill : null}
              keys={keysSoft ? keysSoft : null}
              indexBy="item"
              groupMode="stack"
              interactive={false}
              maxValue={5}
              tickRotation={-10}
              />
          </Box>
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Hard Skills")}
          </Typography>
          <Box height="250px" mt="-20px" mr={1}>
            <BarChart
              isDashboard={true}
              data={hardSkill ? hardSkill : null}
              keys={keysHard ? keysHard : null}
              indexBy="item"
              groupMode="stack"
              interactive={false}
              maxValue={5} 
              tickRotation={-10}
              />
          </Box>
        </Box>
        {/* ROW 4 */}
      </Box>
    </Box>
  );
};

export default SkillsDashboard;
