import { Box, Tab, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { mockPaises } from '../../../data/mockPaises copy'
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import {useFetch} from '../../../hooks/useFetch';
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";
import FormAccountData from "./formAccountData";
import PlanOverview from "./planOverview";


const FormAccount = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [method,setMethod] = useState(null);
  const [data, setData] = useState([]);
  const [contact, setContact] = useState([]);
  const [login, setLogin] = useState([]);
  const [phoneError, setPhoneError] = useState(null);
  const [phoneCellError, setPhoneCellError] = useState(null);

  const [value, setValue] = useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  //recebe id do account para carregar o form para edição
  
  const { id } = useParams();
  const api = process.env.REACT_APP_API_URL;
  //----------------------------------------------------

  useEffect(()=> {
    const fetchAccountAddress = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": `${sessionStorage.getItem('token')}`
          }
        };

        const resp = await fetch(url, options);
        return resp.json();
      }
      try{
        const [accountResponse, contactResponse, loginResponse] = await Promise.all([
          fetchWithHeaders(`${api}/account/${id}`),
          fetchWithHeaders(`${api}/contact/${id}`),
        ]);
        setData(accountResponse);
        setContact(contactResponse);
        setLogin(loginResponse);
      }
      catch (error){
        console.error('Error fetching data:', error);
      } 
    };

    fetchAccountAddress();
  },[id]);
  

  initialValues.email = `${data.email ? data.email : ""}`;
  initialValues.type = `${data.type ? data.type : ""}`;
  initialValues.identifier = `${data.identifier ? data.identifier : ""}`;
  initialValues.company = `${data.company ? data.company : ""}`;
  initialValues.phone = `${data.phone ? data.phone : ""}`;
  initialValues.phoneCell = `${contact.phone2 ? contact.phone2 : ""}`;
  initialValues.address = `${contact.address ? contact.address : ""}`;
  initialValues.city = `${contact.city ? contact.city : ""}`;
  initialValues.country = `${contact.country ? contact.country : ""}`;

  //seta url para cadastro (POST) e grava o retorno da API no Resp
  const [resp, setResp] = useState(null);
  //-------------------------------------------------------------

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const accountUrl = `${api}/account/cadastro`;
    const contactUrl = `${api}/contact/cadastro`;
    
    const account = {
      account_id : id,
      email : values.email,
      phone : values.phone,
      type : values.type,
      identifier : values.identifier,
      company : values.company
    };
   
    const reqAccount = await fetch(accountUrl,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization" : `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(account),
    });
    setResp(reqAccount.status);

    const contact = {
      phone2 : values.phoneCell,
      address : values.address,
      city : values.city,
      country : values.country,
      account_id : id
    };


    const reqContact = await fetch(contactUrl,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization" : `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(contact),
    });
    
  };
  //------------------------------------------------

  const handleValidaTelefone = (phone) => {
    const telefoneRegEx = /^\([1-9]{2}\)9[0-9]{4}\-[0-9]{4}$/;
    if (telefoneRegEx.test(phone)){
      return true;
    }
    else{
      return false;
    }
  };

  const handleLabel = () => {
    if(id){
      return "Update Account";
    }
      return "Create Account";
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Account")} subtitle={t("Update your account data")} />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        //gridAutoRows="140px"
        gap="20px"
        m="5px 0px"
      >
      </Box>
      
      <Box m="0px" >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
            <TabList
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary">
              <Tab label={t("Account Data")} value="0" />
              <Tab label={t("Plan Overview")} value="1" />
              <Tab label={t("Billing Information")} value="2" />
            </TabList>
          </Box>
          <Box>
            <TabPanel value="0" sx={{padding: "20px 0px"}}>
              <FormAccountData />
            </TabPanel>
          </Box>
          <Box>
            <TabPanel value="1" sx={{padding: "20px 0px"}}>
              <PlanOverview />
            </TabPanel>
          </Box>
          <Box>
            <TabPanel value="2" sx={{padding: "20px 0px"}}>

            </TabPanel>
          </Box>
        </TabContext>
      </Box>
      
      {/* GRID & CHARTS */}
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address: yup.string().required("required"),
  city: yup.string().required("required"),
  country: yup.string().required("required"),
});
const initialValues = {
  email: "",
  type : "",
  identifier : "",
  phone: "",
  phoneCell: "",
  address: "",
  city: "",
  country: "",
};

export default FormAccount;