import { Box, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Formik ,Field } from "formik";
import ProgressCircle from "./ProgressCircle";
import '../utils/i18n';
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextEditor from "./TextEditor";

const AccordionFeedback = ({user, team, position, manager, photo}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //initialValues.anotation = anotation;

  return (
    <Box
      backgroundColor={colors.primary[400]}
      flex="grid"
      gridColumn="span 12"
      borderRadius="10px"
    >
      <Accordion sx={{backgroundColor: "transparent"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box width="33%">
            <Typography
              variant="h5"
              //fontWeight="bold"
              sx={{ color: colors.greenAccent[500], padding: "0", cursor: "pointer", textAlign: "left" }}
            >
              {t("Employee Data")}:
            </Typography>
          </Box>
              </AccordionSummary>
                <AccordionDetails>
                  <Box
                  display="flex"
                  justifyContent="space-between"
                  >
                    <Box
                        display="grid"
                        gap="15px"
                        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                        //alignItems="center"
                        width="90%"
                      >
                      <Box
                        gridColumn="span 6"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="10px"
                      >
                        <Box
                          color={colors.greenAccent[500]}
                          fontWeight="bold"
                          fontSize="0.9rem"
                        >
                        {t("Employee")}   
                        </Box>
                        {user}
                      </Box>
                      <Box
                        gridColumn="span 3"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="10px"
                      >
                        <Box
                          color={colors.greenAccent[500]}
                          fontWeight="bold"
                          fontSize="0.9rem"
                        >
                        {t("Team")}   
                        </Box>
                        {team}
                      </Box>
                      <Box
                        gridColumn="span 3"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="10px"
                      >
                        <Box
                          color={colors.greenAccent[500]}
                          fontWeight="bold"
                          fontSize="0.9rem"
                        >
                        {t("Position")}   
                        </Box>
                        {position}
                      </Box>
                      <Box
                        gridColumn="span 12"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="10px"
                      >
                        <Box
                          color={colors.greenAccent[500]}
                          fontWeight="bold"
                          fontSize="0.9rem"
                        >
                        {t("Manager")}   
                        </Box>
                        {manager}
                      </Box>
                    </Box> 
                    <img
                          alt="profile-user"
                          src={photo || `/assets/photo.svg`}
                          style={{ width: "100%", // Faz a imagem se ajustar ao contêiner pai
                                  maxWidth: "90px", // Define um tamanho máximo
                                  height: "auto", // Mantém a proporção da imagem
                                  aspectRatio: "3/4", // Garante que a imagem seja sempre quadrada
                                  objectFit: "cover", // Garante que a imagem preencha o espaço sem distorção
                                  borderRadius: "5px", // Faz a imagem ficar redonda
                                  boxShadow: "0px 0px 18px " + colors.greenAccent[300],
                                  gridColumn: "span 1"
                                }}
                      />
                  </Box>
                </AccordionDetails>
            </Accordion>
    </Box>
  );
};

const initialValues = {
  anotation: "",
};

export default AccordionFeedback;
