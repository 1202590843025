import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamProfile from "../../hooks/useTeam";

const mocklineChart = [
  {
    "id": 0,
    "data": [
        {
            "x": "",
            "y": 0
        },
    ]
  }
];

const mockKeyLineChart = ['People'];


const ThankYouPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [energiaMotivacao, setEnergiaMotivacao] = useState([]);
  
  const location = useLocation();

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/*<Header title="ENERGY AND MOTIVATION DASHBOARD" subtitle="Welcome to energy and motivation level dashboard" />*/}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* ROW 1 */}
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          borderRadius="10px"
          backgroundColor={colors.primary[400]}
          gap="40px"
          padding="40px 40px"

        >
          <Box
            display="flex"
            justifyContent="center"
          >
            <RecommendOutlinedIcon sx={{fontSize: 150, color: colors.grey[100]}} />
            
          </Box>
          <Box
            display="flex"
            justifyContent="center"
          >
            <Typography
                variant="h1"
                fontWeight="bold"
                color={colors.grey[100]}
                sx={{ padding: "0px 10px 0 10px" }}
              >
              THANK YOU!
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
          >
          <Typography
            variant="h3"
            fontWeight="600"
            color={colors.greenAccent[500]}
            sx={{ padding: "0px 10px 0 10px" }}
          >
            Thank you for answering the Happiness Radar survey.
          </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
            >
              Home Page
            </Button>
          </Box>
        </Box>
        {/* ROW 2 */}
        {/* ROW 3 */}
      </Box>
    </Box>
  );
};

export default ThankYouPage;
