import { Box, TextField, Select, MenuItem, InputLabel, Checkbox, FormControl ,FormControlLabel, useTheme, FormGroup, Button, Input } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import TeamProfile from "../../../hooks/useTeam";
import ProfileLogin from "../../../hooks/useLoginUser";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";


const FormUser = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  const[method,setMethod] = useState(null);
  const [data, setData] = useState([]);
  const [resp, setResp] = useState(null);
  const [whatsApp, setWhatsApp] = useState("n");
  const [teleGram, setTelegram] = useState("n");
  const [phoneError, setPhoneError] = useState(null);
  const [team, setTeam] = useState([]);
  const [manager, setManager] = useState([]);
  const [profile, setProfile] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null)

  const navigate = useNavigate();

  
  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  const api = process.env.REACT_APP_API_URL;
  //----------------------------------------------------

  const fetchUser = async () => {
    const url = `${api}/user/${id}/language/${localStorage.getItem('language')}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setData(json);
    setWhatsApp(json.whatsapp);
    setTelegram(json.telegram);
    handlePhoto(json.url_photo);
  };

  const fetchTeam = async () => {
    const url = `${api}/team/account/${ProfileLogin.getAccountId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    if(resp.ok){
      setTeam(json);      
    }
  };

  const fetchManager = async () => {
    const url = `${api}/user/manager/${ProfileLogin.getAccountId()}/profile/manager`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    if(resp.ok){
      setManager(json);
    }
  };

  const fetchProfile = async () => {
    const url = `${api}/profile/allprofile`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    if(resp.ok){
      setProfile(json);
    }
  };

  useEffect(()=> {
    if(id){
      fetchUser();
    }
    fetchTeam();
    fetchManager();
    fetchProfile();
  },[id]);
  

  initialValues.fullName = `${data.fullName ? data.fullName : ""}`;
  initialValues.email = `${data.email ? data.email : ""}`;
  initialValues.phone = `${data.phone ? data.phone : ""}`;
  initialValues.position = `${data.position ? data.position : ""}`;
  initialValues.people_target = `${data.people_target ? data.people_target : ""}`;
  initialValues.team = `${data.team_id ? data.team_id : ""}`;
  initialValues.manager = `${data.manager_id ? data.manager_id : ""}`;
  initialValues.profile = `${data.profile_id ? data.profile_id : ""}`;
  initialValues.date_birth = `${data.date_birth ? data.date_birth : ""}`;
  //initialValues.url_photo = `${data.url_photo ? data.url_photo : ""}`;




  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  const isDark = () => {
    if(theme.palette.mode === "dark"){
      return "secondary";
    }
    else {
      return "primary";
    }
  };

  const handleClickWhatsApp = (e) => {
    if(e.target.checked){
      setWhatsApp("s");
    }
    else{
      setWhatsApp("n");
    }

  };

  const handleClickTeleGram = (e) => {
    if(e.target.checked){
      setTelegram("s");
    }
    else{
      setTelegram("n");
    }
  };

  const maskDate = () => {
    switch(localStorage.getItem('language')){
      case "br":
        return '99/99/9999';
      case "es":
        return '9999-99-99';
      case "us":
        return '9999-99-99';
    }
    return 0;
  };
  

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    //seta url para cadastro (POST) e grava o retorno da API no Resp
    const url = `${api}/user/cadastro`;

    const user = {
      team_user_id: data.team_user_id,
      user_id : parseInt(id),
      team_id: parseInt(values.team),
      fullName : values.fullName,
      date_birth: handleDataHora(values.date_birth),
      email : values.email,
      phone : values.phone,
      whatsapp : whatsApp,
      telegram : teleGram,
      position : values.position,
      people_target : values.people_target,
      manager_id: values.manager && values.manager ? values.manager : null,
      account_id: ProfileLogin.getAccountId(),
      profile_id: values.profile,
      url_photo: selectedFile ? selectedFile.name : data.url_photo
    };
    
    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization" : `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(user),
    });
    setResp(request.status);
    if (request.ok) {
      const { user_id } = await request.json();

      if(selectedFile){
        if(user_id){
          // Criando FormData
          const formData = new FormData();
          formData.append("file", selectedFile);
  
          const resp = await fetch(`${api}/user/upload_photo/${user_id}`, {
              method: "POST",
              headers: {
                  "Authorization": `${sessionStorage.getItem('token')}`
              },
              body: formData,
          });
  
          if (resp.ok) {
              console.log("Imagem enviada com sucesso!");
          } else {
              console.error("Erro ao enviar imagem:", resp.status);
          }
        }
        else{
          // Criando FormData
          const formData = new FormData();
          formData.append("file", selectedFile);
  
          const resp = await fetch(`${api}/user/upload_photo/${id}`, {
              method: "POST",
              headers: {
                  "Authorization": `${sessionStorage.getItem('token')}`
              },
              body: formData,
          });
  
          if (resp.ok) {
              console.log("Imagem enviada com sucesso!");
          } else {
              console.error("Erro ao enviar imagem:", resp.status);
          }
        }
      }
      else{
        //console.log("selectedFile", selectedFile);
      }
    }
  };
  
  const handleLabel = () => {
    if(id){
      return "Update User";
    }
      return "Create User";
  };

  const handleValidaTelefone = (phone) => {
    const telefoneRegEx = /^\([1-9]{2}\)9[0-9]{4}\-[0-9]{4}$/;
    if (telefoneRegEx.test(phone)){
      return true;
    }
    else{
      return false;
    }
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  const handlePhoto = async (url) => {
    
    if (url) {
      try {
        const response = await fetch(`${api}/user/uploaded_file/${url}`);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        console.log('blob',response);
        setPreview(objectUrl);
      } catch (error) {
        console.error("Erro ao carregar imagem:", error);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
  
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        const targetRatio = 3 / 4;
        let width = img.width;
        let height = img.height;
  
        let srcX = 0;
        let srcY = 0;
        let srcWidth = width;
        let srcHeight = height;
  
        if (width / height > targetRatio) {
          srcWidth = height * targetRatio;
          srcX = (width - srcWidth) / 2;
        } else {
          srcHeight = width / targetRatio;
          srcY = (height - srcHeight) / 2;
        }
  
        canvas.width = 150;
        canvas.height = 200;
  
        ctx.drawImage(
          img,
          srcX,
          srcY,
          srcWidth,
          srcHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );
  
        canvas.toBlob(
          (blob) => {
            const resizedFile = new File([blob], file.name, { type: file.type });
            setSelectedFile(resizedFile);
            setPreview(URL.createObjectURL(resizedFile));
          },
          file.type,
          0.95
        );
      };
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" flexDirection="row" justifyContent="space-between" paddingBottom="30px">
        <Header title={t(handleLabel())} subtitle={t("Create or Update Team User")} />
          <Box
            display="flex"
            flexDirection="column"
            gap="5px"
            sx={{
              width: "100px", // Ajuste o tamanho conforme necessário
              height: "130px", // Ajuste para uma proporção comum (ex.: 3:4 ou 4:5)
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.primary[500], // Fundo para preencher espaços vazios
              borderRadius: "5px",
              boxShadow: "0px 0px 18px " + colors.greenAccent[300],
            }}
          >
            <input
              accept="image/*"
              type="file"
              id="upload-button"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="upload-button">
            <div
              style={{
                width: "100px",
                height: "130px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primary[500],
                borderRadius: "5px",
                boxShadow: "0px 0px 18px " + colors.greenAccent[300],
              }}
            >
              <img
                alt="profile-user"
                src={preview || `/assets/photo.svg`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
              />
            </div>
              </label>
          </Box>
      </Box>
      
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(12, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                sx={{ gridColumn: "span 12" }}
              />
               <InputMask
                mask={maskDate()}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.date_birth}
                name="date_birth"
                error={!!(touched.date_birth && errors.date_birth)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.date_birth && errors.date_birth} 
                sx={{ gridColumn: "span 6" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Date of Birth")} />}
              </InputMask>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 6" }}
              />
              <InputMask
                mask={'(99)99999-9999'}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                onBlur={(e) => {
                  if(e.target.value){
                    if(!handleValidaTelefone(e.target.value)){
                      setPhoneError("Telefone inválido");
                    }
                    else{
                      setPhoneError(null);
                    }
                  }
                }}
                value={values.phone}
                name="phone"
                error={!!(touched.phone && errors.phone) || !!phoneError} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.phone && errors.phone ? errors.phone : phoneError} 
                sx={{ gridColumn: "span 6" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Phone")} />}
              </InputMask>
              <Box sx={{ gridColumn: "span 6" }}>
                <FormControlLabel
                  control={<Checkbox
                            name="whatsapp"
                            checked={whatsApp === "s"}
                            size="large"
                            color={isDark()}
                            onClick={handleClickWhatsApp}
                            />}
                  label="WhatsApp"
                  sx={{ padding: 1 }}
                />
                <FormControlLabel
                  control={<Checkbox
                            name="telegram"
                            checked={teleGram === "s"}
                            onClick={handleClickTeleGram}
                            size="large"
                            color={isDark()} />}
                  label="Telegram"
                  sx={{ padding: 1 }}
                />
              </Box>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Position")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.position}
                name="position"
                error={!!touched.position && !!errors.position}
                helperText={touched.position && errors.position}
                sx={{ gridColumn: "span 6" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Tier")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Tier")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.people_target}
                  name="people_target"
                  error={!!touched.people_target && !!errors.people_target}
                  helperText={touched.people_target && errors.people_target}
                  sx={{ gridColumn: "span 6" }}
                  >
                    <MenuItem value={t("Strategic")}>{t("Strategic")}</MenuItem>
                    <MenuItem value={t("Tactical")}>{t("Tactical")}</MenuItem>
                    <MenuItem value={t("Operational")}>{t("Operational")}</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Profile")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Profile")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.profile}
                  name="profile"
                  error={!!touched.profile && !!errors.profile}
                  helperText={touched.profile && errors.profile}
                  sx={{ gridColumn: "span 6" }}
                  >
                    {profile && profile.map((itens) => (
                      <MenuItem value={itens.id}> {t(itens.profile_name)} </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
                <InputLabel>{t("Team")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Team")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.team}
                  name="team"
                  error={!!touched.team && !!errors.team}
                  helperText={touched.team && errors.team}
                  sx={{ gridColumn: "span 6" }}
                  >
                    <MenuItem value={null}>{t("No Team")}</MenuItem> 
                    {team && team.map((itens)=>(
                      <MenuItem value={itens.id}>{t(itens.teamName)}</MenuItem>  
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ gridColumn: "span 12" }}>
                <InputLabel>{t("Manager")}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Manager")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.manager}
                  name="manager"
                  error={!!touched.manager && !!errors.manager}
                  helperText={touched.manager && errors.manager}
                  sx={{ gridColumn: "span 12" }}
                  >
                    <MenuItem value={null}>{t("No Team")}</MenuItem>
                    {manager && manager.map((itens)=>(
                      <MenuItem value={itens.id}>{t(itens.fullName)}</MenuItem>  
                    ))}
                </Select>
              </FormControl>
            </Box>
            <SnackBars buttonName={handleLabel()} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid"),
    // .required("required"),
  position: yup.string().required("required"),
  people_target: yup.string().required("required")
});
const initialValues = {
  fullName: "",
  email: "",
  phone: "",
  position: "",
  people_target: "",
  team: "",
  manager: ""
};

export default FormUser;