import { Box, FormControl, RadioGroup, FormControlLabel, Radio, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import SnackBars from "../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import '../../styles/styles.css'; // Importe seu arquivo CSS contendo as classes fornecidas
import { tokens } from "../../theme";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';


const questions = [
  {
    "value":"1",
    "item":"I'm not gonna say anything. I don't feel comfortable",
    "classification":"I feel totally insecure",
  },
  {
    "value":"2",
    "item":"I won't talk too much, I'll let others bring the problems",
    "classification":"I feel insecure",
  },
  {
    "value":"3",
    "item":"I'll talk about some things, but some others will be hard to talk about",
    "classification":"I don't feel so safe",
  },
  {
    "value":"4",
    "item":"I'll talk about pretty much everything; some things will be hard",
    "classification":"I feel safe",
  },
  {
    "value":"5",
    "item":"No problem, I'll talk about anything",
    "classification":"I feel totally safe",
  },
];

const questoes = [
  {
    "value":"1",
    "item":"Não vou falar nada. não me sinto à vontade",
    "classification":"Me sinto totalmente inseguro",
  },
  {
    "value":"2",
    "item":"Eu não vou falar muito, vou deixar outros trazerem os problemas",
    "classification":"Me sinto inseguro",
  },
  {
    "value":"3",
    "item":"Eu vou falar sobre algumas coisas, mas algumas outras serão difíceis de falar",
    "classification":"Não me sinto tão seguro",
  },
  {
    "value":"4",
    "item":"Eu vou falar sobre praticamente tudo; algumas coisas serão difíceis",
    "classification":"Me sinto seguro",
  },
  {
    "value":"5",
    "item":"Sem problemas, eu vou falar sobre qualquer coisa",
    "classification":"Me sinto totalmente seguro",
  },
];

const FormSafetyCheck = () => {
  const {t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [item, setItem] = useState(null);
  const [classification, setClassification] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState();

  const navigate = useNavigate();

  const api = process.env.REACT_APP_API_URL;

  const {user_id, campaign_id} = useParams();


  const fetchData = async () => {
    const url = `${api}/language/campaign/${campaign_id})}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": sessionStorage.getItem('token')
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setCurrentLanguage(json.language);
  };

  useEffect(() => {
    fetchData();
  },[]);

  const changeLanguage = value => {
    i18n.changeLanguage(value)
    .then(() => {
      setCurrentLanguage(value);
      localStorage.setItem('language',value);
    })
    .catch((err) => {
      console.log(err);
    })
  };



  const handleItemCampaign = (value) => {
    setItem(questions[value - 1].item);
    setClassification(questions[value - 1].classification);
  }

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    handleItemCampaign(event.target.value);
  };
  
  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/safetycheck/cadastro`;
    const myDate = handleDataHora(new Date(Date.now()).toLocaleString().split(',')[0].trim());
    const myHour = new Date(Date.now()).toLocaleString().split(',')[1].trim();
    const answer = {
      item : t(item),
      feeling : "",
      score : parseInt(selectedValue),
      classification : t(classification),
      answer_type : t("Safety"),
      realization_date : myDate,
      realization_time : myHour,
      campaign_id : parseInt(campaign_id),
      user_id : parseInt(user_id)
    };
    const request = await fetch(url,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(answer),
    });
    setResp(request.status);
    if(request.status >= 200 && request.status <= 299){
      navigate(`/formEnergymotivation/${user_id}/${campaign_id}`);
    }
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title={t("SAFETY CHECK")} subtitle={t("How safe do you feel?")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        //validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} >
            <Box>
              <Box id="radio-cards-container">
                <FormControl component="fieldset">
                  {/*<FormLabel component="legend">Escolha uma opção:</FormLabel>*/}
                  <RadioGroup
                    aria-label="opções"
                    name="safety_check"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={t(questions[4].value)}
                      control={<Radio style={{display: 'none'}} />}
                      label={
                        <Box className={`radio-card ${selectedValue === t(questions[4].value) ? 'selected' : ''}`}>
                          <Box className="radio-card-check">
                            {selectedValue === t(questions[4].value) && <i className="material-icons"><CheckCircleOutlinedIcon /> </i>}
                          </Box>
                          <Box className="radio-card-icon">
                            {/*<img src="caminho/para/sua/imagem1.png" alt="Ícone 1" />*/}
                          </Box>
                          <Box className="text-center radio-card-label">
                            {t(questions[4].classification)}
                              <Box className="radio-card-label-description" style={{color: colors.greenAccent[500]}} >
                              {t(questions[4].item)}
                              </Box>
                          </Box>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={t(questions[3].value)}
                      control={<Radio style={{display: 'none'}} />}
                      label={
                        <Box className={`radio-card ${selectedValue === t(questions[3].value) ? 'selected' : ''}`}>
                          <Box className="radio-card-check">
                            {selectedValue === t(questions[3].value) && <i className="material-icons"><CheckCircleOutlinedIcon /></i>}
                          </Box>
                          <Box className="radio-card-icon">
                            {/*<img src="caminho/para/sua/imagem2.png" alt="Ícone 2" />*/}
                          </Box>
                          <Box className="text-center radio-card-label">
                            {t(questions[3].classification)}
                              <Box className="radio-card-label-description" style={{color: colors.greenAccent[500]}}>
                                {t(questions[3].item)}
                              </Box>
                          </Box>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={t(questions[2].value)}
                      control={<Radio style={{display: 'none'}} />}
                      label={
                        <Box className={`radio-card ${selectedValue === questions[2].value ? 'selected' : ''}`}>
                          <Box className="radio-card-check">
                            {selectedValue === t(questions[2].value) && <i className="material-icons"><CheckCircleOutlinedIcon /></i>}
                          </Box>
                          <Box className="radio-card-icon">
                            {/*<img src="caminho/para/sua/imagem3.png" alt="Ícone 3" />*/}
                          </Box>
                          <Box className="text-center radio-card-label">
                            {t(questions[2].classification)}
                              <Box className="radio-card-label-description" style={{color: colors.greenAccent[500]}}>
                                {t(questions[2].item)}
                              </Box>
                          </Box>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={t(questions[1].value)}
                      control={<Radio style={{display: 'none'}} />}
                      label={
                        <Box className={`radio-card ${selectedValue === t(questions[1].value) ? 'selected' : ''}`}>
                          <Box className="radio-card-check">
                            {selectedValue === t(questions[1].value) && <i className="material-icons"><CheckCircleOutlinedIcon /></i>}
                          </Box>
                          <Box className="radio-card-icon">
                            {/*<img src="caminho/para/sua/imagem3.png" alt="Ícone 3" />*/}
                          </Box>
                          <Box className="text-center radio-card-label">
                            {t(questions[1].classification)}
                              <Box className="radio-card-label-description" style={{color: colors.greenAccent[500]}}>
                                {t(questions[1].item)}
                              </Box>
                          </Box>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={t(questions[0].value)}
                      control={<Radio style={{display: 'none'}} />}
                      label={
                        <Box className={`radio-card ${selectedValue === t(questions[0].value) ? 'selected' : ''}`}>
                          <Box className="radio-card-check">
                            {selectedValue === t(questions[0].value) && <i className="material-icons"><CheckCircleOutlinedIcon /></i>}
                          </Box>
                          <Box className="radio-card-icon">
                            {/*<img src="caminho/para/sua/imagem3.png" alt="Ícone 3" />*/}
                          </Box>
                          <Box className="text-center radio-card-label">
                            {t(questions[0].classification)}
                              <Box className="radio-card-label-description" style={{color: colors.greenAccent[500]}}>
                                {t(questions[0].item)}
                              </Box>
                          </Box>
                        </Box>
                      }
                    />
                    {/* Adicione mais FormControlLabel para mais opções, se necessário */}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <SnackBars buttonName={t("SAVE SAFETY CHECK")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  safety_check: yup.string().required("required"),
});
const initialValues = {
  safety_check: "",
};

export default FormSafetyCheck;