import { Box, useTheme, IconButton, Typography, Switch} from "@mui/material";
import { Formik ,Field } from "formik";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {useEffect, useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import LoginProfile from "../../hooks/useLoginUser";
import TeamProfile from "../../hooks/useTeam";
import { useLocation } from 'react-router-dom';
import '../../utils/i18n';
import { useTranslation } from "react-i18next";
import AccordionFeedback from "../../components/AccordionFeedbackUser"; 
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import StatAction from "../../components/StatAction";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import RadarType from "../../components/RadarChart";
import BarChart from "../../components/BarChart";
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import TextEditor from "../../components/TextEditor";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const mockRows = [{
  "id": 1,
  "teamName": undefined,
  "campaign_name": undefined,
  "people_target": undefined,
  "initial_date": undefined,
  "end_date": undefined,
  "objective": undefined
}];

const ReportFeedback = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [className, setClassName] = useState();

  const api = process.env.REACT_APP_API_URL;

  const location = useLocation();

  const navigate = useNavigate();

  //recebe id do account para carregar o form para edição
  const { id, userId } = useParams();

  //initialValues.anotation = `${anotation ? anotation : ""}`;
  //initialValues.mrjohnes = "";

  //----------------------------------------------------
  
  const [performance, setPerformance] = useState(null);
  const [impact, setImpact] = useState(null);
  const [score, setScore] = useState(0);
  const [hardSkill, setHardSkill] = useState(0);
  const [softSkill, setSoftSkill] = useState(0);
  const [user, setUser] = useState([]);
  const [pdi, setPdi] = useState(0);
  const [turnover, setTurnouver] = useState("N/A");
  const [selectedBox, setSelectedBox] = useState(null); // Estado para armazenar o id do componente selecionado
  const [preview, setPreview] = useState(null);
  const [dataRadarChart, setDataRadarChart] = useState([]);
  const [avgSkills, setAvgSkills] = useState([]);
  const [anotation, setAnotation] = useState(null);
  const [insight, setInsight] = useState();
  const [risk, setRisk] = useState();
  const [titleRadar, setTitleRadar] = useState(localStorage.getItem('switch_radarchart_feedback') ? "Score List" : "Skills Radar");
  const [checked, setChecked] = useState(localStorage.getItem('switch_radarchart_feedback') ? (/true/).test(localStorage.getItem('switch_radarchart_feedback')) : false);


  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleTitleRadar(event.target.checked);
    localStorage.setItem('switch_radarchart_feedback', event.target.checked);
  };

  const handleTitleRadar = (checked) => {
    if(checked){
      setTitleRadar("Score List");
    }
    else{
      setTitleRadar("Skills Radar");
    }
  }

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  const fetchUser = async () => {
    const url = `${api}/user/${userId}/language/${localStorage.getItem('language')}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setUser(json);
    
    handlePhoto(json.url_photo);
  };

  const handlePhoto = async (url) => {
    
    if (url) {
      try {
        const response = await fetch(`${api}/user/uploaded_file/${url}`);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setPreview(objectUrl);
      } catch (error) {
        console.error("Erro ao carregar imagem:", error);
      }
    }
  };

 
  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": sessionStorage.getItem('token')
          }
        };
        const resp = await fetch(url, options);
        return resp.json();

      };
      try{
        const [bigNumberResponse, pdiResponse, radarChartResponse, avgSkillsResponse, anotationResponse, insightResponse, riskResponse] = await Promise.all([
          fetchWithHeaders(`${api}/assessment/performancefeedback/feedback/${id}`),
          fetchWithHeaders(`${api}/actionplan/listpdiperformanceuser/${userId}/language/${localStorage.getItem("language")}`),
          fetchWithHeaders(`${api}/assessment/dataradarchartfeedback/${id}`),
          fetchWithHeaders(`${api}/assessment/avgskillsfeedback/${id}`),
          fetchWithHeaders(`${api}/feedback/${id}`),
          fetchWithHeaders(`${api}/insight/feedback/${id}`),
          fetchWithHeaders(`${api}/happinessradar/riskturnover/user/${userId}`),
        ]);
        const { average_score, Hardskill, Softskill, classification, impact } = bigNumberResponse[0];
        setScore(average_score ? average_score : 0);
        setHardSkill(Hardskill ? Hardskill : 0);
        setSoftSkill(Softskill ? Softskill : 0);
        setPerformance(classification ? classification : "N/A");
        setImpact(impact ? impact : 'N/A');
        setPdi(pdiResponse ? pdiResponse[0].percent: 0);
        setDataRadarChart(radarChartResponse ? radarChartResponse : null);
        setAvgSkills(avgSkillsResponse ? avgSkillsResponse : null);
        setAnotation(anotationResponse.anotation);
        setInsight(insightResponse ? insightResponse : "");
        setRisk(riskResponse.risk)
      }
      catch (error) {
        console.error("Error fetching data", error);
      }

    };
    fetchUser();
    fetchData();
  }, []);

  const handleKeysDetail = (barChart) => {
    const key = new Array();
    if(barChart){
      barChart.map((itens) =>{
        if(!key.includes(itens.classification)){
          key.push(itens.classification);
        }
        return itens.classification;
      });
    }
   return key;
  };


  const handlePercent = (planejado, realizado) => {
    if(realizado === 0){
      return 0;
    }
    else{
      try{
        let result = ((realizado / planejado) * 100);
        return result.toFixed(0);
      }
      catch(error){
        console.log(error);
        return error;
      }
    }
  }

  const handleLanguageDate = () => {
    switch(localStorage.getItem('language')){
      case 'br':
        return 'pt-BR';
      case 'us':
        return 'en-US';
      case 'es':
        return 'es-ES';
    }
  }

  return (
    <Box m="10px 20px 20px 20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Feedback Report")} subtitle={t("Feedback reporting to support 1:1 meetings")} />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        //gridAutoRows="140px"
        //gap="20px"
        m="0px 0px 15px"
      >
        <AccordionFeedback
          user={user.fullName}
          team={user.teamName}
          position={user.position}
          manager={user.leader}
          photo={preview}
        />
      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(20, 1fr)"
        gridAutoRows="5px"
        gap="20px"
        //m="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={t(performance)}
            subtitle={t("Classification")}
            progress={20 / 100}
            increase={20 + "%"}
            variant="h4"
            icon={
              <DriveFileRenameOutlineOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={score}
            subtitle={t("Performance")}
            progress={20 / 100}
            increase={20 + "%"}
            variant="h2"
            icon={
              <AssistantOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/** 
        <Box
          gridColumn="span 2"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={softSkill}
            subtitle={t("Soft Skills")}
            progress={20 / 100}
            increase={20 + "%"}
            variant="h2"
            icon={
              <ManageAccountsOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={hardSkill}
            subtitle={t("Hard Skills")}
            progress={35 / 100}
            increase={35 + "%"}
            variant="h2"
            icon={
              <LocalLibraryOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        */}
        <Box
          gridColumn="span 4"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={pdi}
            subtitle={t("PDP")}
            progress={35 / 100}
            increase={35 + "%"}
            variant="h2"
            icon={
              <PercentOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={risk ? t(risk) : "N/A" }
            subtitle={t("Turnover Risk")}
            progress={35 / 100}
            increase={35 + "%"}
            variant="h3"
            icon={
              <LoopIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatAction
            title={impact ? t(impact) : "N/A" }
            subtitle={t("Turnover Impact")}
            progress={35 / 100}
            increase={35 + "%"}
            variant="h3"
            icon={
              <WarningAmberOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/** LINHA 3 */}
        <Box
          gridColumn="span 10"
          gridRow="span 12"
          backgroundColor={colors.primary[400]}
          p="5px 10px 35px 10px"
          borderRadius="10px"
        >
          <Box
            //mt="25px"
            //p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h5" fontWeight="600" sx={{ padding: "0 0 8px 0" }}>
                {t(`${titleRadar}`)}
              </Typography>
            </Box>
            <Box>
              <Switch
                size="small"
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: colors.greenAccent[500],
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: colors.grey[300],
                  },
                }}
              />
            </Box>
          </Box>
          
          { checked ? 
              <Box display="flex" flexDirection="row" gap={3} p="0px 0px">
                <Box display="flex" flexDirection="column" gap={1} width="50%">
                  {/**COLUNA 1 */}
                  {dataRadarChart.map((item, index) => (
                    index <= 5 ?
                    <Box
                      backgroundColor={colors.primary[500]}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="right"
                      borderRadius="5px"
                      padding="1px"
                      gap="5px"
                    >
                      {t(item.subject)}:
                      <Box
                      backgroundColor={colors.greenAccent[500]}
                      fontWeight="bold"
                      fontSize="0.9rem"
                      p="5px 10px"
                      borderRadius="4px"
                      minWidth="50px"
                      display="flex"
                      justifyContent="center"
                    >
                      {item.fullMark}
                      </Box>
                    </Box>
                  : null
                  ))}
                </Box>
                {/**COLUNA 2 */}
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  //padding="10px"
                  width="50%"
                >
                  {dataRadarChart.map((item, index) => (
                  index > 5 ?
                    <Box
                      backgroundColor={colors.primary[500]}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="right"
                      borderRadius="5px"
                      padding="1px"
                      gap="5px"
                    >
                      {t(item.subject)}:
                      <Box
                      backgroundColor={colors.greenAccent[500]}
                      fontWeight="bold"
                      fontSize="0.9rem"
                      p="5px 10px"
                      borderRadius="4px"
                      minWidth="50px"
                      display="flex"
                      justifyContent="center"
                    >
                      {item.fullMark}
                      </Box>
                    </Box>
                  : null
                      
                  ))}
                </Box>
              </Box>
          : <RadarType data={dataRadarChart ? dataRadarChart : null} />
          }
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
          </Box>
        </Box>
        {/* BAR CHART */}
        <Box
          gridColumn="span 10"
          gridRow="span 12"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "10px 30px 0 30px" }}
          >
            {t("Skills Levels")}
          </Typography>
          <Box height="280px" mt="-25px">
            <BarChart
              isDashboard={true}
              data={avgSkills ? avgSkills : null}
              keys={['Hardskill','Softskill']}
              indexBy="classification"
              groupMode="stack"
              interactive={false}
              maxValue={5} 
              />
          </Box>
        </Box>
        <Box
          gridColumn="span 10"
          gridRow="span 13"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "10px 30px 0 30px" }}
          >
            {t("Feedback")}
          </Typography>
          <Box mt="0px" padding="10px 30px 80px 30px">
            <Formik
              initialValues={{ anotation: anotation ? anotation : "" }}
              enableReinitialize={true}
            >
              <Field
                name="anotation"
                component={TextEditor}
                sx={{ gridColumn: "span 12" }}
                minLines={6}
                maxLines={8}
                readOnly={true}
              />
            </Formik>
          </Box>
        </Box>
        
        <Box
          gridColumn="span 10"
          gridRow="span 13"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "10px 30px 0 30px" }}
          >
            {t("Insights")}
          </Typography>
          <Box mt="0px" padding="10px 30px 80px 30px">
            <Formik
              initialValues={{ insight: insight ? insight.closing_remarks + "</br></br>" + insight.plan : "" }}
              enableReinitialize={true}
            >
              <Field
                name="insight"
                component={TextEditor}
                sx={{ gridColumn: "span 12" }}
                minLines={6}
                maxLines={8}
                readOnly={true}
              />
            </Formik>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

{/**
const initialValues = {
  anotation: "",
  mrjohnes: "",
};
*/}
export default ReportFeedback;
