import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import {useEffect, useState} from 'react';
import FAB from "../../../components/FAB";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'; 
import TeamProfile from "../../../hooks/useTeam";
import LoginProfile from "../../../hooks/useLoginUser";
import { useLocation } from 'react-router-dom';
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";

const mockRows = [{
  "id": 1,
  "teamName": undefined,
  "campaign_name": undefined,
  "people_target": undefined,
  "initial_date": undefined,
  "end_date": undefined,
  "objective": undefined
}];

const PlanOverview = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const api = process.env.REACT_APP_API_URL;

  const location = useLocation();
  
  const[list, setList] = useState([]);
  const[campaign, setCampaign] = useState([]);

  let row = [];

  const handleCampaign = (id) => {
    campaign.map(function(item){
      if(item.id === id){
        //setRow(item);
        row = item;
      }
      return item;
    });
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  const columns = [
    {field: "id", headerName: "ID",flex:0.2},
    {
      field: "product_name",
      headerName: t("Product"),
      flex: 2,
    },
    {
      field: "company",
      headerName: t("Company"),
      flex: 2,
    },
    {
      field: "create_date",
      headerName: t("Create Date"),
      flex: 1.5,
    },
    {
      field: "product_duration",
      headerName: "Duration",
      flex: 1.5,
    },
    {
      field: "time_remaining",
      headerName: t("Time Remaining"),
      flex: 1.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "total_users",
      headerName: t("Total Users"),
      flex: 1.5,
    },
  ];

  const fetchData = async () => {
    const url = `${api}/product/productbyaccount/${LoginProfile.getAccountId()}/language/${localStorage.getItem('language')}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json(resp);
    setCampaign(json);
  };

  useEffect(() => {
    fetchData();
  }, [TeamProfile.getTeamID(),location.pathname]);

  
  return (
    <Box m="0px">
      <Box
        m="0px"
        height="64vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-row": {
            borderBottom: "solid 1px",
            borderBottomColor: colors.grey[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid 
          checkboxSelection
          rows={campaign ? campaign : false }
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onSelectionModelChange={setList}
      />
      </Box>
    </Box>
  );
};

export default PlanOverview;
